import { getResource } from 'API/getResource'
import { getUrl } from 'API/getUrl'
import { PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React, { useEffect, useState } from 'react'
import { getResourceSubmission } from 'API/getResource'
import { NonUndefined, PromiseType } from 'utility-types'
const ReactQuill = typeof window !== 'undefined' ? require('react-quill') : () => <></>
import 'quill/dist/quill.snow.css'
import 'styles/index.scss'

export type AssetType = {
	token?: string
	name: string
	description: string
	doc?: boolean
	render?: boolean
	cdn: {
		name?: string
		file_extension?: string
		original_name?: string
	}
}

const ResourceView: React.FC<PageProps> = ({ params: { id, resource_id } }) => {
	const { ShowDetails, viewAs } = useGetCourseDetails(id)
	const [assetSubmission, setAssetSubmission] = useState<NonUndefined<PromiseType<NonUndefined<ReturnType<typeof getResourceSubmission>>>>>([])
	const [asset, setAsset] = useState<AssetType>()
	useEffect(() => {
		const run = async () => {
			const asset = await getResource(resource_id, viewAs)

			if (asset) {
				setAsset(asset)
			}
		}
		run()
	}, [resource_id, viewAs])
	useEffect(() => {
		const run = async () => {
			if (viewAs === 'Teacher') {
				const submission = await getResourceSubmission(resource_id, id)
				if (submission) {
					setAssetSubmission(submission)
					console.log(submission)
				}
			}
		}
		run()
	}, [resource_id, viewAs])
	return (
		<>
			{ShowDetails}
			{asset && (
				<>
					<h2 className='pt-2'>{asset.name}</h2>
					<p className='pt-2'>
						<ReactQuill
							theme='snow'
							value={asset.description}
							readOnly={true}
							modules={{
								toolbar: false,
							}}
						/>
					</p>
					<p>
						{asset.token && (
							<>
								<button
									onClick={async () => {
										console.log(asset)
										if (window !== undefined && asset.token) {
											// const data = await handleApi(
											// 	axios.get()
											// )
											// const url = window.URL.createObjectURL(new Blob([data as ArrayBuffer]))
											const link = document.createElement('a')
											link.href = getUrl(
												`asset/resource_attachment_download/${asset?.cdn?.original_name?.replace(
													'.',
													''
												)}?resource_id=${resource_id}&token=${asset.token}`
											)
											link.target = '_blank'
											// link.setAttribute('download', resource_id + '_' + asset.name + '_' + asset.cdn?.file_extension ?? '') //or any other extension
											document.body.appendChild(link)
											link.click()
										}
									}}
									className='btn btn-primary'
								>
									Download
								</button>
							</>
						)}
					</p>
					{asset.token && asset.render && (
						<iframe
							style={{ width: '100%', height: '30rem' }}
							src={getUrl(`asset/resource_attachment?resource_id=${resource_id}&token=${asset.token}`)}
							frameBorder='0'
						></iframe>
					)}
				</>
			)}
			{asset && viewAs === 'Teacher' && (
				<div>
					<div className='row mt-5'>
						<div className='col-6'>
							<span>
								<h4>Viewed Student</h4>
							</span>
							<table className='table'>
								<thead>
									<tr>
										<th scope='col'>Index</th>
										<th scope='col'>Name</th>
										<th scope='col'>Username</th>
										<th scope='col'>Time</th>
									</tr>
								</thead>
								<tbody>
									{assetSubmission
										.filter((assetSubmission) => assetSubmission.isOpened === true)
										.map((elem, index) => {
											return (
												<>
													<tr>
														<th scope='row'>{index + 1}</th>
														<td>
															{elem.first_name} {elem.last_name}
														</td>
														<td>{elem.username}</td>
														<td>{new Date(elem.last_open_date).toLocaleString()}</td>
													</tr>
												</>
											)
										})}
								</tbody>
							</table>
						</div>
						<div className='col-6'>
							<span>
								<h4>Not Viewed</h4>
							</span>
							<table className='table'>
								<thead>
									<tr>
										<th scope='col'>Index</th>
										<th scope='col'>Name</th>
										<th scope='col'>Username</th>
									</tr>
								</thead>
								{assetSubmission
									.filter((assetSubmission) => assetSubmission.isOpened == false)
									.map((elem, index) => {
										return (
											<>
												<tr>
													<th scope='row'>{index + 1}</th>
													<td>
														{elem.first_name} {elem.last_name}
													</td>
													<td>{elem.username}</td>
												</tr>
											</>
										)
									})}
							</table>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default ResourceView
