import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { AssetType } from 'pages/course/[id]/activities/assignment/[assignment_id]'
import { UserType } from 'types/UserAdditionMetaData'

export async function getResource(resource_id: string, viewAs: string) {
	return await handleApi<AssetType & { cdn: { file_name?: string; original_name?: string; file_extension: string } }>(
		axios.get(getUrl(`asset/resource?resource_id=${resource_id}&asTeacher=${viewAs === 'Teacher'}`), getDefaultOptions())
	)
}
export async function getResourceSubmission(resource_id: string, course_id: string) {
	return await handleApi<(UserType & { isOpened: boolean; last_open_date: Date })[]>(
		axios.get(getUrl(`asset/get_resource_submission_list?resource_id=${resource_id}&course_id=${course_id}`), getDefaultOptions())
	)
}
